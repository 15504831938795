import React from "react";
import Img from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";

export default function OurSolutions() {
  return (
    <StaticQuery
      query={graphql`
        query {
          pcImage1: file(relativePath: { eq: "index/purchasers.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 488) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          pcImage2: file(relativePath: { eq: "index/financiers.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 488) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          pcImage3: file(relativePath: { eq: "index/suppliers.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 488) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={(data) => (
        <section className="solutions-area">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">
                Answering your business problems
              </span>
              <h2>Our Solutions</h2>
            </div>

            <div className="solution-area">
              <div className="solution-area_image">
                <Img
                  fluid={data.pcImage1.childImageSharp.fluid}
                  fadeIn={true}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </div>
              <div className="solution-area_card">
                <span className="subheading">Purchasers</span>
                <h5 className="heading">Online Localized platform</h5>

                <ul className="solution-list">
                  <li>Comprehensive tracking of all suppliers</li>
                  <li>Simple Contracts generation (Seamless generation of clear and simple contracts)</li>
                  <li>Real-time invoice tracking</li>
                  <li>
                    Automated KYC with Artificial Intelligence used for
                    monitoring
                  </li>
                  <li>Complete process automation</li>
                </ul>

                {/* <button className="default-btn no-icon">Learn More</button> */}
              </div>
            </div>

            <div className="solution-area inverse">
              <div className="solution-area_image">
                <Img
                  fluid={data.pcImage2.childImageSharp.fluid}
                  fadeIn={true}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </div>
              <div className="solution-area_card">
                <span className="subheading">Financiers</span>
                <h5 className="heading">Online Funding Platform</h5>

                <ul className="solution-list">
                  <li>Invoice exposure </li>
                  <li>Short-term lending opportunites </li>
                  <li>Simple contracts generation </li>
                  <li>Predictive Credit model</li>
                  <li>
                    Artificial Intelligence driven KYC and Reconciliation{" "}
                  </li>
                  <li>Transparent and Auditable transactions</li>
                  <li>Low - risk predictable return on investment</li>
                </ul>

                {/* <button className="default-btn no-icon">Learn More</button> */}
              </div>
            </div>

            <div className="solution-area">
              <div className="solution-area_image">
                <Img
                  fluid={data.pcImage3.childImageSharp.fluid}
                  fadeIn={true}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </div>
              <div className="solution-area_card">
                <span className="subheading">Suppliers</span>
                <h5 className="heading">Online Supply-Chain Platform</h5>

                <ul className="solution-list">
                  <li>Online payment facilities for notified invoices</li>
                  <li> Exposure to new business leads </li>
                  <li>Hassle-free sign up and onboarding </li>
                  <li>Invoice payment tracking </li>
                  <li>Complete process automation </li>
                  <li>Easy access to credit/financing facilities</li>
                </ul>

                {/* <button className="default-btn no-icon">Learn More</button> */}
              </div>
            </div>
          </div>
        </section>
      )}
    />
  );
}
