import React from "react";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import MainBanner from "../components/Index/MainBanner";
import BestChoice from "../components/Index/BestChoice";
import ContactUs from "../components/Index/ContactUs";
import Testimonials from "../components/Index/Testimonials";
import Alliances from "../components/Index/Alliances";
import OurSolutions from "../components/Index/OurSolutions";
import SEO from "../components/App/SEO";

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <MainBanner />
      <BestChoice />
      <OurSolutions />
      <Alliances />
      {/* <Testimonials /> */}
      <ContactUs />
      <Footer />
    </Layout>
  );
};

export default Home;
