import React from "react";
import ModalVideo from "react-modal-video";
import scrollTo from "gatsby-plugin-smoothscroll";
import Img from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";
import ReactWOW from "react-wow";

// const OwlCarousel = Loadable(() => import("react-owl-carousel3"));

const options = {
  loop: false,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: false,
  items: 1,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
};

export default function MainBanner() {
  // Popup Video
  const [isOpen, setIsOpen] = React.useState(true);
  const openModal = () => {
    setIsOpen(!isOpen);
  };

  // Owl Carousel
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query {
          bannerImage: file(relativePath: { eq: "banner-image-1.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={(data) => (
        <React.Fragment>
          {/* If you want to change the video need to update videoID */}
          <ModalVideo
            channel="youtube"
            isOpen={!isOpen}
            videoId="bk7McNUjWgw"
            onClose={() => setIsOpen(!isOpen)}
          />

          {/* {display ? ( */}
          {/* <OwlCarousel
               className="home-slides owl-carousel owl-theme"
               {...options}
             > */}
          <div className="main-banner-item">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="banner-item-content">
                    <div>
                      {/* <span className="sub-title">Welcome</span> */}
                      <ReactWOW delay=".1s" animation="fadeInLeft">
                        <h1>Turn Invoices into Cash Fast</h1>
                      </ReactWOW>
                      <ReactWOW delay=".2s" animation="fadeInLeft">
                        <p>
                          Invoice factoring gives you control of your business
                          cash flow and eliminates waiting on customer payments
                        </p>
                      </ReactWOW>
                      <ReactWOW delay=".3s" animation="fadeInLeft">
                        <div className="btn-box">
                          <button
                            onClick={() => scrollTo("#best-choice")}
                            className="default-btn no-icon"
                          >
                            Learn More <span></span>
                          </button>

                          {/* <Link
                          to="#play-video"
                          onClick={(e) => {
                            e.preventDefault();
                            openModal();
                          }}
                          className="video-btn popup-youtube"
                        >
                          <i className="flaticon-google-play"></i> Watch Video
                        </Link> */}
                        </div>
                      </ReactWOW>
                    </div>
                    <div className="banner-image">
                      <Img
                        fluid={data.bannerImage.childImageSharp.fluid}
                        fadeIn={true}
                        // objectFit="cover"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ) : (
             </OwlCarousel>
             ""
           )} */}
        </React.Fragment>
      )}
    />
  );
}
