import React from "react";

class ChoiceCard extends React.Component {
  render() {
    return (
      <div className="choice-card">
        <div className="choice-flex">
          <img
            className="choice-card_icon"
            src={this.props.icon}
            alt="icon"
          ></img>
          <div className="wrap-detail">
            <h3 className="choice-card_title">{this.props.title}</h3>
            <p className="choice-card_description">{this.props.description}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ChoiceCard;
