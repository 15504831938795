import React from "react";
import firstImage from "../../assets/images/index/aml-ctf.svg";
import secondImage from "../../assets/images/index/credit-risk.svg";
import thirdImage from "../../assets/images/index/trust.svg";
import fourthImage from "../../assets/images/index/interoperability.svg";
import fivethImage from "../../assets/images/index/responsive.svg";
import sixthImage from "../../assets/images/index/speed-completion.svg";
import seventhImage from "../../assets/images/index/competitive.svg";
import eightthImage from "../../assets/images/index/art-technology.svg";
import ChoiceCard from "./ChoiceCard";

const BestChoice = () => {
  return (
    <section id="best-choice" className="pt-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            Enabling the key business pillars with technology and automation
          </span>
          <h2>Why We Are The Best Choice</h2>
          <p>
          SEA Easy Capital harnesses technology to minimize the cost of financial intermediation, 
          passing benefits directly to financiers and borrowers
          </p>
        </div>

        <div className="row choice-cards">
          <div className="col-lg-6 col-md-12 section-1">
            <h5 className="heading">Best for Financiers</h5>
            <div className="wrap-choice-card">
              <ChoiceCard
                icon={firstImage}
                title="AML/CTF KYC"
                description="Our Digital Due Diligence framework allows for in-depth background checks, 
                providing auditable compliance and transparency Interoperability & Standards Based"
              />
            </div>
            <div className="wrap-choice-card">
              <ChoiceCard
                icon={secondImage}
                title="Credit Risk Assessment"
                description="Self-refining Digital Credit Assessment technology provides guidance for 
                competitive and well-informed financing decisions"
              />
            </div>
            <div className="wrap-choice-card">
              <ChoiceCard
                icon={thirdImage}
                title="Trust &amp; Transparency"
                description="Business logic based on enabling transparent stakeholder management on our platform to build trust and transparency"
              />
            </div>
            <div className="wrap-choice-card">
              <ChoiceCard
                icon={fourthImage}
                title="Interoperability &amp; Standards Based"
                description="Robust platform built with interoperability at its core, 
              while complying with international standards and governance"

              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 section-2">
            <h5 className="heading">Best for Suppliers</h5>
            <div className="wrap-choice-card">
              <ChoiceCard
                icon={fivethImage}
                title="Quick Turnaround"
                description="Our customer service team will connect with you instantly 
                and walk you through the process to obtain funds"
              />
            </div>
            <div className="wrap-choice-card">
              <ChoiceCard
                icon={sixthImage}
                title="Fast funding and onboarding"
                description="Get easy funding approval within 24-48hours and access cash upto $500,000"
              />
            </div>
            <div className="wrap-choice-card">
              <ChoiceCard
                icon={seventhImage}
                title="Transparent pricing"
                description="100% transparent and competitive pricing. No hidden fees or charges"/>
            </div>
            <div className="wrap-choice-card">
              <ChoiceCard
                icon={eightthImage}
                title="State-of-the-art Technology"
                description="Automated invoicing and a friendly dashboard with all information at your fingertips"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestChoice;
