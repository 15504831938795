import React from "react";
import ContactForm from "../Contact/ContactForm";
import phone from "../../assets/images/phone.svg";
import email from "../../assets/images/email.svg";
import whatsapp from "../../assets/images/whatsapp.svg";
import location from "../../assets/images/location.svg";
import GoogleMapReact from "google-map-react";
import LeafletMapSEC from "../Common/LeafletMapSEC";

const ContactUs = (props) => {
  return (
    <section className="contact-us-section pt-70 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="section-title">
              <span className="sub-title">Connect Today</span>
              <h2>Contact Us</h2>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <a className="contact-info" href="tel:+65 985 047781">
                    <img src={phone} alt="icon" />
                    +65 985 047781
                  </a>
                  <a
                    className="contact-info"
                    href="mailto:info@seaeasycapital.com"
                  >
                    <img src={email} alt="icon" />
                    info@seaeasycapital.com
                  </a>
                  <a
                    className="contact-info"
                    href="https://api.whatsapp.com/send?phone=65985047781"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={whatsapp} alt="icon" />
                    +65 985 047781
                  </a>
                </div>
                <div className="col-lg-6 col-md-12">
                  <span className="contact-info">
                    <img src={location} alt="icon" />
                    38, North Canal Road, Singapore 059294
                  </span>
                </div>
              </div>
              <div className="contact-map">
                {/* <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyDH4nPJ4ji81JP4177bi2ukiExEsk0jQac",
                  }}
                  defaultCenter={[34.0522, -118.2437]}
                  defaultZoom={10}
                ></GoogleMapReact> */}
                <div className="mymap">
                  {typeof window !== "undefined" && (
                    <LeafletMapSEC
                      position={[1.2873123503388268, 103.84699577272488]} // Your Coordinates
                      zoom={16} // Zoom Level
                      markerText={"Local Cafe, 65 Park Row"} // Icon text
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
