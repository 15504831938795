import React from "react";
import userImg from "../../assets/images/user.png";
class TestimonialItem extends React.Component {
  render() {
    return (
      <div className="single-testimonials-item">
        <p>{this.props.testimonialText}</p>

        <div className="client-info">
          <div className="d-flex justify-content-start align-items-center">
            {this.props.img ? (
              <img src={this.props.image} alt="testimonial-item" />
            ) : (
              <img src={userImg} alt="testimonial-item" />
            )}

            <div className="title">
              <h3>{this.props.name}</h3>
              <span>{this.props.company}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestimonialItem;
